import React from "react";
import { Row, Col, Container, Navbar } from "react-bootstrap";
import "../sass/App.scss";
import ImageCarousel from "./ImageCarousel";
import Greeting from "./Greeting";
import BeerCards from "./BeerCards";

function Layout() {
  return (
    <>
      <header>
        <Navbar bg="primary" className="navbar-dark shadow">
          <Container>
            <Navbar.Brand href="#home">Nerdbrygg.no</Navbar.Brand>
          </Container>
        </Navbar>
      </header>
      <main>
        <Container>
          <Row className="my-4">
            <Col>
              <Greeting />
            </Col>
          </Row>

          <Row className="my-4">
            <Col>
              <ImageCarousel />
            </Col>
          </Row>

          <Row className="my-5">
            <BeerCards />
          </Row>
        </Container>
      </main>
      <footer className="text-center py-4">
        &copy; 2021 Jørn Lemika & Anne-Berit Murberg
      </footer>
    </>
  );
}

export default Layout;
