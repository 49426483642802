import React from "react";

function Greeting() {
  return (
    <>
      <h1>Julen 2021</h1>
      <p>God jul og Godt nytt år ønskes dere alle &#128522; </p>
      <p>
        Ønsker å starte årets julehilsen med å hylle vår kjæreste onkel Knut som
        sovnet stille inn i Februar, savnet er stort, men minnene er gode
        &#10084;
      </p>
      <p>
        Året har vært full av fantastiske turer i nord, vi har vært med elvebåt
        i Reisaelva og gått en hau med perleturer i Alta, Havøysund, Lakselv,
        Karasjok, Øksfjord og Hammerfest. Men årets tur høydepunkt er
        selvfølgelig Sørøya på langs. Vi startet med å fly til Hasvik å ente opp
        i Langstrand. Helt fantastisk natur. Turen anbefales på det sterkeste.
      </p>
      <p>
        I Oktober fikk vi endelig spise indisk i Oslo, og vi var også i det
        store utland, når vi var i Finland på shopping i hele 45 min &#128517;
        Vi begynte også på årets oppussing av trapp og gang, som vi håper blir
        ferdig en gang! &#128517;
      </p>
      <p>
        Vi takker for 2021, å ønsker dere en fortsatt strålende jul og nytt år
        &#127876; &#129392;
      </p>
    </>
  );
}

export default Greeting;
