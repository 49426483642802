import React from "react";
import { Card, Col } from "react-bootstrap";

function BeerCards() {
  return (
    <>
      <h2 className="mb-4">Årets julegave</h2>
      <Col xs={12} md={4}>
        <Card className="shadow">
          <Card.Img variant="top" src="images/bottle.png" className="" />
          <Card.Body>
            <Card.Title>Juleøl med lakris</Card.Title>
            <Card.Text>7.2%</Card.Text>
          </Card.Body>
        </Card>
      </Col>
      <Col xs={12} md={4}>
        <Card className="shadow">
          <Card.Img variant="top" src="images/bottle.png" className="" />
          <Card.Body>
            <Card.Title>Ingefærøl</Card.Title>
            <Card.Text>4.4%</Card.Text>
          </Card.Body>
        </Card>
      </Col>
      <Col xs={12} md={4}>
        <Card className="shadow">
          <Card.Img variant="top" src="images/bottle.png" className="" />
          <Card.Body>
            <Card.Title>Blondineøl</Card.Title>
            <Card.Text>6.2%</Card.Text>
          </Card.Body>
        </Card>
      </Col>
    </>
  );
}

export default BeerCards;
