import React from "react";
import { Carousel } from "react-bootstrap";

function ImageCarousel() {
  return (
    <>
      <Carousel>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="images/Fjelltopp.jpg"
            alt="Gjesp!"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="images/jorn_amanda_valper.jpg"
            alt="Jørn og Amanda med valper"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="images/polaris.jpg"
            alt="Polaris i hundehuset sitt"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="images/20210703_180213.jpg"
            alt="Langstrand"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="images/20210705_101749.jpg"
            alt="Reisaelva"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="images/20210801_165250.jpg"
            alt="Reisaelva"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="images/20210801_205637.jpg"
            alt="Gjesp!"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="images/20210803_132245.jpg"
            alt="Gjesp!"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="images/20210803_202152.jpg"
            alt="Gjesp!"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="images/20210806_180830.jpg"
            alt="Gjesp!"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="images/20210814_122724.jpg"
            alt="Gjesp!"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="images/20210909_184811.jpg"
            alt="Gjesp!"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="images/20210911_151751.jpg"
            alt="Potet!"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="images/komagfjord_blomst.jpg"
            alt="Gjesp!"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="images/20210916_181323.jpg"
            alt="Gjesp!"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="images/20210930_114052.jpg"
            alt="Gjesp!"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="images/20211016_112522.jpg"
            alt="Gjesp!"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="images/Reisaelva.jpg"
            alt="Gjesp!"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="images/Snapchat-503593646.jpg"
            alt="Gjesp!"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src="images/Knut.jpg" alt="Gjesp!" />
        </Carousel.Item>
      </Carousel>
    </>
  );
}

export default ImageCarousel;
